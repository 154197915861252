import { Component, OnInit } from '@angular/core';
import { ROUTES } from './sidebar-routes.config';
import { Router } from '@angular/router';
import { RouteInfo } from './sidebar.metadata';

declare var $: any;
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  public menuItems: RouteInfo[] = [];
  constructor(
    private router: Router
  ) {}

  ngOnInit() {
  }


  showContent(event) {
    const target = event.target || event.srcElement || event.currentTarget;
  }
}
