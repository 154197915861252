export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyD2i8MDfSEOVzIu3jd0SPhRINK68o8TNf8',
    authDomain: 'ipc-digital-cms.firebaseapp.com',
    databaseURL: 'https://ipc-digital-cms.firebaseio.com',
    projectId: 'ipc-digital-cms',
    storageBucket: 'ipc-digital-cms.appspot.com',
    messagingSenderId: '529199348996'
  }
};
