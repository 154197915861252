import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  toggleClass = 'ft-maximize';
  public isCollapsed = true;
  public display = true;
  avatar: string;
  userCompany: any;
  constructor(private router: Router) {
    if(window.location.href==='https://www.tubusonline.com/')
      this.display = false;
  }

  ToggleClass() {
    if (this.toggleClass === 'ft-maximize') {
      this.toggleClass = 'ft-minimize';
    } else this.toggleClass = 'ft-maximize';
  }

  logout() {
    this.router.navigate(['/login']);
  }
  settings() {
    this.router.navigate(['/themes']);
  }
  click(route) {
    this.router.navigate(['/'+route]);
  }

}
