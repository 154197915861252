import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FullLayoutComponent } from './layouts/full/full-layout.component';
const appRoutes: Routes = [
  // {
  //   path: '',
  //   canActivate: [AuthGuard],
  //   component: FullLayoutComponent,
  //   children: [
  //     {
  //       path: '',
  //       loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  //     },
  //   ]
  // },
  // {
  //   path: 'login',
  //   loadChildren: () => import('./shared/auth/auth.module').then(m => m.AuthModule)
  // },
  {
    path: '',
    loadChildren: () => import('./public/poster/poster.module').then(m => m.PosterModule)
  }
];
@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
