import { Component, OnInit, Input } from '@angular/core';
import { NgbAlertConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.scss'],
  providers: [NgbAlertConfig]
})
export class FullLayoutComponent implements OnInit {
  @Input() public alerts: Array<string> = [];
  private _success = new Subject<string>();
  successMessage: string;
  staticAlertClosed = false;

  constructor(alertConfig: NgbAlertConfig) {
    // customize default values of alerts used by this component tree
    alertConfig.type = 'success';
    alertConfig.dismissible = false;
  }

  ngOnInit(): void {
    setTimeout(() => (this.staticAlertClosed = true), 20000);

    this._success.subscribe(message => (this.successMessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => (this.successMessage = null));
  }

  public changeSuccessMessage(message: string) {
    this._success.next(message);
  }
}
