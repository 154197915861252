import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent{
    public display = true;
    constructor(private router: Router){
    if(window.location.href==='https://www.tubusonline.com/')
      this.display = false;
  }
    go(route){
        this.router.navigate([route])
    }
}
